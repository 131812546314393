<template>
  <div class="table-wrap black">
    <el-table
      border
      :data="list"
      style="width: 100%"
      :row-style="{ height: 0 }"
      :cell-style="{ padding: '8px 0' }"
      :default-sort = "{prop: 'createAt', order: 'descending'}"
      @sort-change="sortChange"
    >
      <el-table-column prop="tempId" label="ID" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="realName" label="姓名" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="mobile" label="手机号" align="center" width="110"></el-table-column>
      <el-table-column prop="merchantName" label="商户" align="center" min-width="140" v-if="roleId == 1"></el-table-column>
      <el-table-column prop="device" align="center" label="登录设备" show-overflow-tooltip></el-table-column>
      <el-table-column
        prop="createAt"
        :formatter="dateFormat"
        align="center"
        label="注册时间"
        width="155"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="status"
        align="center"
        label="认证状态"
        class-name="table-verify"
        width="150"
      >
        <template slot-scope="scope">
          <el-tag
            v-for="(tag , index) in verifyList(scope.row)"
            :key="index"
            size="mini"
            :effect="tag.value ? 'dark' : 'plain'"
            :type="tag.value ? '' : 'info'"
            :class="tag.value? 'active' : ''"
          >{{ tag.name }}</el-tag>
        </template>
      </el-table-column>
<!--      <el-table-column align="center" label="审核结果" width="82">-->
<!--        <template slot-scope="scope">-->
<!--          <el-tag-->
<!--            :effect="exam[scope.row.status].efc"-->
<!--            :type="exam[scope.row.status].type"-->
<!--            size="mini"-->
<!--          >{{ exam[scope.row.status].text}}</el-tag>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column label="操作" align="center" width="120">
        <template slot-scope="scope">
          <!-- 编辑 -->
<!--          <el-button-->
<!--            type="success"-->
<!--            size="mini"-->
<!--            icon="el-icon-edit"-->
<!--            circle-->
<!--            @click.native.prevent="$emit('edit', scope.row)"-->
<!--          ></el-button>-->
          <!-- 查看 -->
          <el-button
            type="danger"
            size="mini"
            icon="el-icon-search"
            circle
            @click="$emit('view', scope.row)"
          ></el-button>
          <!-- 推广 -->
          <el-button
            v-if="scope.row.verify_status.operator && !scope.row.isRecommend && pro"
            type="info"
            size="mini"
            icon="el-icon-thumb"
            circle
            @click="handleRecommend(scope.row)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import format from '@/mixins/date.js';
import service from "../../users/api";

export default {
  mixins: [format],
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  components: {
  },
  data() {
    return {
      exam: {
        0: { type: 'primary', text: '通过', efc: 'dark' },
        1: { type: 'danger', text: '拒绝', efc: 'dark' },
        2: { type: 'info', text: '未处理', efc: 'plain' },
        3: { type: 'info', text: '资料不全', efc: 'dark' }
      }
    };
  },
  computed: {
    // 认证状态
    verifyList() {
      return function(row) {
        let { verify_status = {} } = row;
        let { basic_info = false, real_name = false, operator = false } = verify_status;
        return [
          { name: '实名', value: real_name },
          { name: '基础', value: basic_info },
          { name: 'YYS', value: operator }
        ];
      };
    },
    roleId(){
      return this.$store.state.XINYOU_ADMIN_USER.role_id;
    }
  },
  methods:{
    sortChange(val) {
      this.$emit('sortChange', val)
    },
    //推荐
    handleRecommend(row) {
      this.$alert(
        `是否确认将用户${row.username || row.mobile}进行推广?`,
        "提示",
        { showCancelButton: true }
      ).then(() => {
        service
          .recommend({ userId: row.userId || null, isRecommend: 1 })
          .then((res) => {
            this.$parent.init(this.$parent.currentPage);
            this.$message({ type: "success", message: "操作成功！" });
          });
      }).catch(() => {})
    }
  }
};
</script>

<style lang="scss" scoped>
.black {
  .tag {
    color: #fff;
  }
  .un-tag {
    color: #666;
  }
  .table-verify {
    .el-tag + .el-tag {
      margin-left: 3px;
    }
    .active.el-tag {
      background-color: #5766da;
      color: #fff;
      border: 1px solid #5766da;
    }
  }
}
</style>
