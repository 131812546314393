import API from "@/api/index";

export default {
  getList: param => {
    return API.requestGet({
      params: {
        type: 1,
        ...param
      },
      url: "user/getList"
    });
  },
  edit: params => {
    return API.requestPost({
      data: params,
      url: "user/ediBlack"
    });
  },
  recommend: params => {
    return API.requestPost({
      data: params,
      url: "user/ediRecommend"
    });
  },
  delUser:param => {
    return API.requestPost({ data: param, url: "user/delUser" });
  },
};
