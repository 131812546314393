<template>
  <div class="main-wrap">
    <!-- 搜索 -->
    <search-form @search="search" :show-exam="false"></search-form>
    <black-table :list="list" @edit="handleEdit" @view="handleView" @recommend="handleRecommend" @sortChange="handleSort"></black-table>
    <!-- 分页管理 -->
    <el-pagination
      background
      :page-sizes="pageSizes"
      :page-size="10"
      layout="total, sizes, prev, pager, next"
      :total="page.allNum"
      @size-change="changeSize"
      @current-change="changePage"
      :current-page="currentPage"
    ></el-pagination>
    <!-- 查看 -->
    <view-bar :show-view="showView" @close="closeView"></view-bar>
  </div>
</template>

<script>
// components
import blackTable from "../black/components/black-table";
import searchForm from "../components/search-form";
import viewBar from "@/components/viewBar";

// utils
import pagination from "@/mixins/pagination";
import service from "../users/api";
import view from "@/components/viewBar/view";
export default {
  mixins: [pagination, view],
  data() {
    return {
      list: [],
      page: {},
      searchData: {},
      currentPage: 1,
    };
  },
  components: {
    blackTable,
    searchForm,
    viewBar,
  },
  methods: {
    // 初始化
    init(currentPage) {
      let initParams = { pageNum: currentPage, pageSize: 10 };
      return this.getList(initParams);
    },
    // 搜索
    search(data) {
      let params = Object.assign(data, {status:2, pageNum: 1 });
      this.searchData = data;
      return this.getList(params);
    },
    // 获取表格数据
    getList(params) {
      // 切换当前页码
      let { pageNum = 1 } = params;
      this.currentPage = pageNum;
      return service.getList({status:2, ...params}).then((res) => {
        let { list = [], page = {} } = res;
        list.forEach((item, index) => {
          item.tempId = (params.pageNum - 1) * page.pageSize + index + 1;
        });
        this.list = list;
        this.page = page;
      });
    },
    handleEdit(row) {
      this.$alert(
        `是否确认将用户${row.username || row.mobile}加入到黑名单?`,
        "编辑",
        { showCancelButton: true }
      ).then(() => {
        return service
          .edit({ userId: row.userId || null, type: 1 })
          .then((res) => {
            this.init(this.currentPage);
            this.$message({ type: "success", message: "操作成功！" });
          });
      });
    },
    //推荐
    handleRecommend(row) {
      this.$alert(
        `是否确认将用户${row.username || row.mobile}进行推广?`,
        "提示",
        { showCancelButton: true }
      ).then(() => {
        service
          .recommend({ userId: row.userId || null, isRecommend: 1 })
          .then((res) => {
            this.init(this.currentPage);
            this.$message({ type: "success", message: "操作成功！" });
          });
      }).catch(() => {})
    },
  },
  mounted() {
    this.init(this.currentPage);
  },
};
</script>

<style lang="scss" scoped></style>
